import { ref, computed, watch } from '@vue/composition-api'
// import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'
import { debounce } from 'lodash'

export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()

  store.dispatch('tenant/getAll')

  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)

  const tableColumns = [
    { label: 'iotcomms_master_account.title', key: 'name', sortable: true },
    { label: 'iotcomms_master_account.email', key: 'email', sortable: true },
    { label: 'iotcomms_master_account.password', key: 'password', sortable: true },
    { label: 'iotcomms_master_account.number_devices', key: 'devices_interphones_count', sortable: true },
    { label: 'iotcomms_master_account.token', key: 'token', sortable: true },
    { label: 'iotcomms_master_account.domain', key: 'domain', sortable: true },
    { label: 'api', key: 'api', sortable: true },
    { label: 'websocket', key: 'websocket', sortable: true },
    { label: 'tenants.title', key: 'tenants', sortable: false, formatter: (value, key, item) => {
      if(item.tenants) return [...new Set(item.tenants.map(item => { return item.name}))].join(', ')
      return ""
      }
    },
    { label: 'date.created_at', key: 'created_at', sortable: true, formatter: 'formatDateAssigned' },
    { label: 'date.updated_at', key: 'updated_at', sortable: true, formatter: 'formatDateAssigned' },
  ]

  if (ability.can('update', 'IotcommsMasterAccount') === true || ability.can('delete', 'IotcommsMasterAccount') === true) {
    tableColumns.splice(0, 0, { label: 'actions.name', key: 'actions' })
  }

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const action = ref('list')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    store.dispatch('iotcomms_master_account/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      id_tenant: tenantFilter.value,
      action: action.value
    })
      .then(response => {
        if (action.value == 'export') {
          action.value = 'list'
        } else {
          callback(response.data)
          totalList.value = response.meta.total
        }
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([ tenantFilter, currentPage, perPage ], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,

    refetchData,

    tenantOptions,
    tenantFilter,
    action,
  }
}

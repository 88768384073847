<template>
  <b-sidebar id="sidebar" :visible="isSidebarActive" bg-variant="white"
             sidebar-class="sidebar-lg" shadow backdrop
             no-header no-close-on-backdrop right @shown="loadData" @hidden="resetForm"
             @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0 text-capitalize">
          {{ `${isAdd ? $t('actions.add') : $t('actions.edit')} ${ $tc('iotcomms_master_account.title', 1)}`}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
        >

          <!-- name  -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('iotcomms_master_account.name')"
              label-for="device_id"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tenant -->
          <validation-provider
            #default="validationContext"
            name="tenant"
          >
            <b-form-group
              :label="$tc('tenants.title', 2)"
              label-for="tenant"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.tenants"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tenantOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="tenant"
                multiple
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="email"
            rules="required|email"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('iotcomms_master_account.email')"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="itemData.email"
                :state="getValidationState(validationContext)"
                trim
                autocomplete="nuevo@omnicloud.mx"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Serial number -->
          <validation-provider
            #default="validationContext"
            name="password"
            :rules="isAdd ? 'required': ''"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('iotcomms_master_account.password')"
              label-for="name"
            >
              <b-form-input
                id="password"
                v-model="itemData.password"
                :state="getValidationState(validationContext)"
                trim
                type="password"
                autocomplete="cambiame123"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Token -->
          <validation-provider
            #default="validationContext"
            name="token"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('iotcomms_master_account.token')"
              label-for="token"
            >
              <b-form-input
                id="token"
                v-model="itemData.token"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- api url -->
          <validation-provider
            #default="validationContext"
            name="api"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              label="api url"
              label-for="api"
            >
              <b-form-input
                id="api"
                v-model="itemData.api"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

        <!-- websocket url -->
          <validation-provider
            #default="validationContext"
            name="websocket"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              label="websocket url"
              label-for="websocket"
            >
              <b-form-input
                id="websocket"
                v-model="itemData.websocket"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- iot id" -->
          <validation-provider
            #default="validationContext"
            name="domain"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('iotcomms_master_account.domain')"
              label-for="domain"
            >
              <b-form-input
                id="domain"
                v-model="itemData.domain"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ isAdd ? $t('actions.add') : $t('actions.edit') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t('actions.cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email, positive, required_if } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent
  from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { computed } from '@vue/composition-api/dist/vue-composition-api'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    tenantOptions: {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    }
  },
  setup(props, { emit }) {
    const blankData = {
      email: '',
      password: '',
      token: '',
      domain: '',
      unit: []
    }

    const toast = useToast()

    const itemData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData))
    }

    const onSubmit = () => {
      let req = {
        email: itemData.value.email,
        password: itemData.value.password,
        token: itemData.value.token,
        domain: itemData.value.domain,
        tenants: itemData.value.tenants,
        name: itemData.value.name,
        api: itemData.value.api,
        websocket: itemData.value.websocket,
      }

      let dispatch;

      if (props.isAdd) {
        dispatch = store.dispatch('iotcomms_master_account/add', req)
      } else {
        const data = {
          id: itemData.value.id,
          params: req,
        }

        dispatch = store.dispatch('iotcomms_master_account/edit', data)
      }

      dispatch
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          emit('refetch-data')
          emit('update:is-sidebar-active', false)
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm
    } =
      formValidation(resetData)

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit
        itemData.value.tenants = props.itemEdit.tenants ? props.itemEdit.tenants.map(tenant => (tenant.id)) : null
      }
    }

    return {
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
    }
  },
}
</script>